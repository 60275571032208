<template>
  <div class="Popover">
    <el-popover placement="bottom" ref="gridTable">
      <div class="PopoverInfo">
        <el-tabs tab-position="top" @tab-click="GetTabs">
          <el-tab-pane v-for="(item, index) in Type" :key="index" :label="item.name"></el-tab-pane>
        </el-tabs>
        <div class="sreach">
          <el-autocomplete v-model="state" :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect" size="small" clearable @clear="clearInput"></el-autocomplete>
          <el-button type="primary" size="small" @click="checkSelect">搜索</el-button>
        </div>
        <!-- 数据源 -->
        <div>
          <!-- 服务内容 -->
          <el-tabs tab-position="left" v-model="activeName">
            <el-tab-pane v-for="(item, index) in list" :key="index" :label="item.itemsName" :name="item.itemsName">
              <div class="list">
                <div class="items" :class="items.id == ListIndex ? 'items_active' : ''" v-for="(items, index) in item.children" :key="index" @click="checkItem(items.id)">{{ items.itemsName }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <el-input v-model="Config.value" :placeholder="placeholder" slot="reference" @clear="clearValue" suffix-icon="el-icon-arrow-down"></el-input>
    </el-popover>
  </div>
</template>
<script>
// let pinyin = require("js-pinyin");
export default {
  data() {
    return {
      Config: {
        value: "", //输出信息
        id: "",
        ids: "",
      },
      Type: [
        {
          name: "全部",
          sort: null,
        },
      ],
      TypeIndex: null,
      Search: "", //模糊搜索
      SortId: null,
      SortId2: null,
      ListIndex: null, //锁定id 不用索引
      GetShow: true,
      state: "",
      restaurants: [],
      activeName: "",
    };
  },
  watch: {
    // 监听变化
    TypeIndex(newVal) {
      if (newVal) {
        // 对数组进行 打标签
        this.list.forEach((item) => {
          if (item.platformType == this.TypeIndex) {
            item.show = true;
          } else {
            item.show = false;
          }
        });
      } else {
        this.list = this.Resetting(this.list);
      }
    },
    SortId(newVal) {
      if (newVal) {
        this.ListIndex = newVal;
        this.list.forEach((item) => {
          item.children.forEach((items) => {
            if (items.id == newVal) {
              this.activeName = item.itemsName;
              items.show = true;
            } else {
              items.show = false;
            }
          });
        });
      }
    },
    list(newVal) {
      this.Resetting(newVal);
      this.list = newVal;
      // this.activeName = newVal[0].itemsName;
    },
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    // 标签中文名称
    plantName: {
      type: String,
      default: "",
    },
    list: Array,
  },
  mounted() {},
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(it) {
      // this.SortId2 = item.id;
      this.$nextTick(() => {
        this.$refs.gridTable.doClose();
      });
      this.ListIndex = it.id;
      this.list.forEach((item) => {
        item.children.forEach((items) => {
          if (items.id == it.id) {
            this.Config.value = items.itemsName;
            this.Config.ids = items.id;
            this.Config.id = items.pid;
          }
        });
      });
      this.$emit("GetOpen", this.Config);
    },

    checkSelect() {
      this.SortId = this.SortId2;
    },

    checkItem(id) {
      this.$nextTick(() => {
        this.$refs.gridTable.doClose();
      });
      // this.$refs.gridTable.doClose()
      this.ListIndex = id;
      this.list.forEach((item) => {
        item.children.forEach((items) => {
          if (items.id == id) {
            this.Config.value = items.itemsName;
            this.Config.ids = items.id;
            this.Config.id = items.pid;
          }
        });
      });
      this.$emit("GetOpen", this.Config);
    },

    clearInput() {
      // this.SortIndex = 0;
    },
    clearValue() {
      this.ListIndex = null;
    },

    GetTabs(e) {
      if (e.index == 0) {
        this.TypeIndex = null;
      } else {
        this.TypeIndex = this.Type[e.index].sort;
      }
    },
    // 重置筛选
    Resetting(data) {
      data.forEach((item) => {
        item.children.forEach((items) => {
          items.show = true;
          items.value = items[this.plantName];
          this.restaurants.push(items);
        });
      });
      return data;
    },
  },
};
</script>
<style lang="less" scoped>
.PopoverInfo {
  padding: 14px 28px;
  box-sizing: border-box;
  width: 700px;
  position: relative;
  .sreach {
    position: absolute;
    top: 15px;
    right: 0;
    // width: 300px;
  }
}

// .Sort {
//   display: flex;
//   align-items: center;

//   .SortList {
//     width: calc(100% - 60px);
//     justify-content: space-between;
//   }

//   .SortType,
//   .SortList {
//     display: flex;
//     align-items: center;

//     .item,
//     .items {
//       color: #888888;
//       font-size: 16px;
//       padding: 0 8px;
//       cursor: pointer;
//     }

//     .items_active {
//       background: #eaf2ff;
//       color: #3d8bff !important;
//     }
//   }
// }

.list {
  display: flex;
  flex-wrap: wrap;

  .items {
    color: #888888;
    font-size: 16px;
    margin-right: 20px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
  }

  // .items:nth-child(6n) {
  //   margin-right: 0 !important;
  // }

  .items_active {
    background: #eaf2ff;
    border-radius: 4px 4px 4px 4px;
    color: #3d8bff !important;
  }
}
</style>
