<template>
  <!--
    思路 用 dropdown 开发
    移入 显示 弹框
    trigger="click"
  -->
  <div class="Popover">
    <el-dropdown ref="mydropdown" trigger="click">
      <div class="Popover-model el-dropdown-link">
        <el-input placeholder="请选择平台" v-model="config.value"></el-input>
        <i class="el-icon-arrow-down el-icon--right"></i>
        <div class="zzc"></div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <div class="Popover-dropdown-menu">
          <div class="PopoverInfo">
            <el-tabs tab-position="top" @tab-click="clickTab">
              <el-tab-pane v-for="(item, index) in type" :key="index" :label="item"></el-tab-pane>
            </el-tabs>
            <div class="sreach">
              <el-autocomplete v-model="state" placeholder="请输入内容" size="small" clearable
                               :fetch-suggestions="querySearch"
                               :value-key="plantName"
                               @select="checkItem" @clear="clearInput" @click="handFocus">
              </el-autocomplete>
              <el-button type="primary" size="small" @click="checkSelect">搜索</el-button>
            </div>
            <div class="Sort">
              <div class="SortType">
                <div class="items" :class="index === sortIndex ? 'items_active' : ''"
                     v-for="(item, index) in sort" :key="index" @click="clickSort(index)">{{ item }}</div>
              </div>
              <div class="SortList">
                <div class="items" :class="index === pinyinIndex ? 'items_active' : ''"
                     v-for="(item, index) in pinyinList" :key="index" @click="clickPinyin(index)">{{ item }}</div>
              </div>
            </div>
            <!-- 数据源 -->
            <div class="list" :style="{minHeight: !curList || !curList.length ? '100px' : '100%'}">
              <div class="items" :class="item.id === config.id ? 'items_active' : ''"
                   v-for="(item, index) in curList" :key="index" @click="checkItem(item)">
                {{ item.platformName }}
              </div>
              <div class="No-list" v-if="!curList || !curList.length">未找到相关数据</div>
            </div>
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
let pinyin = require("js-pinyin");
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    // 标签中文名称
    plantName: {
      type: String,
      default: "",
    },
    list: Array,
  },
  data() {
    return {
      config: {
        value: "",
        id: "",
      },
      type: ['全部', '国内', '跨境'],
      // 当前选中的 Tab index
      typeIndex: 0,
      search: "", //模糊搜索
      sort: ["全部"],
      sortIndex: 0, // 状态只能存在一个
      pinyinList: new Array(26).fill(null).map((v, i) => String.fromCharCode(65 + i)),
      pinyinIndex: -1,
      state: "",
      SearchList: [], //搜索出来的内容
    };
  },
  
  watch: {
  },
  computed: {
    curList() {
      if (!this.list) return
      let arr = this.list.filter(item => {
        let name = pinyin.getCamelChars(item.platformName)
        if (name && name.length) item.pinyin = name[0]
        return true
      })
      if (this.typeIndex > 0) {
        arr = arr.filter(item => item.platformType === this.typeIndex)
      }
      
      if (this.pinyinIndex > -1) {
        arr = arr.filter(i => i.pinyin === this.pinyinList[this.pinyinIndex])
      }

      return arr
    }
  },
  methods: {
    querySearch(queryString, cb) {
      var list = this.curList;
      var results = queryString
        ? list.filter(item => item.platformName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        : list;
      // 调用 callback 返回建议列表的数据
      this.SearchList = results;
      cb(results);
    },
    createFilter(queryString) {
      return (list) => {
        return list.platformName.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      this.checkItem(item)
    },

    checkSelect() {
      if (!this.state) return this.$modal.msgError("请选择要搜索的内容!");
      this.$modal.msgSuccess("搜索到" + this.SearchList.length + "条数据!");
      // this.SortId = this.SortId2;
      // this.SortIndex = null;
      // 给newlist 赋值
      this.curList = this.SearchList;
    },
    checkItem(item) {
      this.$refs.mydropdown.hide();
      this.config.id = item.id
      this.config.value = item.platformName
      // 给父 组件发送消息
      this.$emit("checkItem", {
        pid: item.pid,
        id: item.id,
      });
    },

    clearInput() {
      this.sortIndex = 0;
      this.pinyinIndex = -1
    },
    handFocus() {
      this.$refs.mydropdown.show();
    },
    clickTab(tab) {
      this.typeIndex = +tab.index;
    },
    clickSort(index) {
      this.pinyinIndex = -1
      this.sortIndex = index
    },
    clickPinyin(index) {
      this.sortIndex = -1
      this.pinyinIndex = index
    },

  },
};
</script>
<style lang="less" scoped>
.Popover-model {
  width: 154px !important;
  height: 47px !important;
  position: relative;
  .zzc {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  /deep/ .el-input {
    height: 47px;
  }
  /deep/ .el-input__inner {
    height: 47px !important;
  }
}
.Popover-model {
  width: 130px;
  height: 40px;
  position: relative;
  i {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    color: #c0c4cc;
  }
}
.Popover-dropdown-menu {
  position: absolute;
  left: -200px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
/deep/ .popper__arrow {
  left: -88px !important;
  top: -6px !important;
}
.el-dropdown-menu {
  border: none;
}
.PopoverInfo {
  padding: 14px 28px;
  box-sizing: border-box;
  max-width: 800px;
  position: relative;
  .sreach {
    position: absolute;
    top: 15px;
    right: 28px;
    // width: 300px;
  }
}

.Sort {
  display: flex;
  align-items: center;

  .SortList {
    width: calc(100% - 60px);
    justify-content: space-between;
  }

  .SortType,
  .SortList {
    display: flex;
    align-items: center;

    .item,
    .items,
    div {
      color: #888888;
      font-size: 16px;
      padding: 0 8px;
      cursor: pointer;
    }

    .items_active {
      background: #eaf2ff;
      color: #3d8bff !important;
    }
  }
}
.No-list {
  color: #888888;
  font-size: 16px;
  text-align: center;
  line-height: 100px;
  width: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
  .items {
    color: #888888;
    font-size: 16px;
    margin-right: 20px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
  }

  .items:nth-child(6n) {
    margin-right: 0 !important;
  }

  .items_active {
    background: #eaf2ff;
    border-radius: 4px 4px 4px 4px;
    color: #3d8bff !important;
  }
}
</style>
